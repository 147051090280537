<template>
  <div>
    <div class="cell_title_detail" :style="{width:width, transform:transform}">
      <div class="detailHeadTitle">
        {{ headTitle }}
      </div>
      <div class="detailTitle" :style="{color:color}">
        {{ contentTitle }}
      </div>
    </div>
    <div v-if="showLine" style="width: calc(100% - 10px);height: 1px;background-color: lightsteelblue;opacity: 0.5;"></div>
  </div>
</template>

<script>
export default {
  name: 'cell_title_detail',
  props: {
    transform:{
      default:'translate(10px, 0px)'
    },
    showLine:{
      default:true,
    },
    width:{
      default:'calc(100% - 20px)'
    },
    color:{
      default:'#999999'
    },
    headTitle: {
      default: ''
    },
    contentTitle: {
      default: ''
    },
  },
}
</script>

<style lang="scss" scoped>

.cell_title_detail {
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;


  .detailHeadTitle {
    min-width: 70px;
    max-width: 120px;
    height: 44px;
    line-height: 44px;
    text-align: left;
    margin-left: 10px;
  }

  .detailTitle {
    margin-right: 10px;
    line-height: 44px;
    text-align: right;
    word-wrap: break-word;
    max-width: calc(100% - 90px);
    min-width: calc(100% - 140px);;
  }
}

</style>
