<template>
  <div class="deviceDetailManager" id="deviceDetailManager">
    <cell_title_detail v-for="(item, index) in dataList" :width="'100%'" :transform="'translate(0px, 0px)'"
                       :head-title="item.title" :content-title="getValue(item)"
    ></cell_title_detail>

    <svg-icon style="right: 10px;margin-top:10px;float: right" width="32px" height="32px"
              name="add"
              @click="doSomeThing"
    />

    <div class="imageContent">
      <div class="deviceDetailView">
        <img v-show="imageUrl!=''" class="deviceImg" :src="imageUrl" alt=""/>
        {{ imageUrl == '' ? '暂无图片' : '' }}
      </div>


      <el-carousel :autoplay="false" @change="carouselChange" type="card" height="150px" arrow="always">
        <el-carousel-item v-for="(item, imageIndex) in imageList" :key="item.id">
          <div class="swiper-slide"
               :class="{'select_swiper-slide':index==imageIndex, 'unselect_swiper-slide':index!=imageIndex}"
          >
            <img class="swiper-slide_img" :src="item.url + '&scala=0.3'" alt=""/>
            <div
                class="swiper-slide_text"
            >
              {{ item.remark }}
            </div>
          </div>


        </el-carousel-item>
      </el-carousel>
    </div>

    <el-button style="width: 90%;transform: translate(5.55%, 0px);margin-top: 10px;margin-bottom: 10px" type="danger"
               @click="shouldDelete(index)"
    >删除
    </el-button>


    <el-dialog
        :append-to-body="true"
        title="提示"
        :visible.sync="deleteDialogVisible"
        width="80%"
    >
      <span>{{ deviceMsg == '' ? `是否删除该设备${deviceMsg}` : '是否删除该图片' }}</span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteDeviceImage">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        :append-to-body="true"
        title="提示"
        :visible.sync="addImageDialogVisible"
        width="80%"
    >

      <img class="add_img" :src="image">

      <div class="input-suffix">
        <div class="title">图片描述:</div>
        <el-input
            class="imageInput"
            size="mini"
            v-model="imageText"
            placeholder="请输入内容"
        >
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addImageDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveImageToServer">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Cell_title_detail from '@/components/cell/cell_title_detail/cell_title_detail'
import { Loading } from 'element-ui'

export default {
  name: 'deviceDetailManager',
  components: {
    Cell_title_detail
  },
  props: {},
  computed: {
    deviceMsg: function() {
      if (this.index >= this.imageList.length) {
        return ''
      }
      this.deviceImage = this.imageList[this.index]
      return this.deviceImage['remark']
    },
    imageUrl: function() {
      if (this.index >= this.imageList.length) {
        return ''
      }
      this.deviceImage = this.imageList[this.index]
      return this.deviceImage['url']
    },
    imageList: function() {
      if (this.$valueIsExist(this.deviceInfo, 'photos') == false) {
        return []
      }
      let list = this.deviceInfo['photos']
      return list
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      imageText: '',
      showImage: false,
      image: '',
      deviceInfo: {},
      loadingView: null,
      loading: false,
      dataList: [],
      addImageDialogVisible: false,
      deleteDialogVisible: false,
      index: 0,
      deviceImage: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.wxConfig(corpInfo)
        } catch (e) {

        }
      }
      this.updateViewList()
      this.updateImage()
    })
  },
  methods: {
    deleteDeviceImage: function() {
      this.deleteDialogVisible = false
      if (this.$valueIsExist(this.deviceInfo, 'photos') == false) {
        return
      }
      let list = this.deviceInfo['photos']
      list.splice(this.index, 1)
      this.updateDeviceInfo()
    },
    doSomeThing: function(item) {
      this.chooseImage().then(res => {
        this.imageText = ''
        if (res.result == false) {
          this.addImageDialogVisible = false
          this.image = ''
          this.$message.error('请重新选择图片')
          this.saveImage('')
          return
        }
        this.addImageDialogVisible = true
        this.image = res.image
        this.saveImage(res.localID)
      })
    },
    updateImage: function() {
      this.getImage().then(res => {
        this.imageText = ''
        if (res.result == false) {
          this.addImageDialogVisible = false
          this.image = ''
          return
        }
        this.addImageDialogVisible = true
        this.image = res.image
      })
    },
    carouselChange: function(index) {
      this.index = index
    },
    getValue: function(item) {
      if (this.$valueIsExist(this.deviceInfo, item.key) == false) {
        return ''
      }

      if (item.key == 'createTime') {
        return this.formatDate(this.deviceInfo[item.key])
      }

      return this.deviceInfo[item.key]
    },
    startLoading: function() {
      let dom = document.getElementById('deviceDetailManager')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    getDetail: function() {
      let query = this.$route.query
      if (this.$valueIsExist(query, 'id') == false) {
        return
      }
      let loadData = (id) => {
        let tenantId = ''

        let userInfo = this.$store.getters.userInfo
        if (this.$valueIsExist(userInfo, 'tenantId')) {
          tenantId = userInfo['tenantId']
        }

        let type = ''
        let qurey = this.$route.query
        if (this.$valueIsExist(qurey, 'type')) {
          type = qurey['type']
        }

        let getPost = {
          id: id
        }
        this.$api.deviceManager.getDeviceDetail(getPost).then(res => {
          if (typeof res.data != 'undefined' && res.data != null) {
            this.deviceInfo = res.data
          } else {
            this.deviceInfo = {}
          }
          this.loading = false

        }).catch(e => {
          this.deviceInfo = {}
          this.loading = false
        })
      }

      loadData(query['id'])

    },
    updateViewList: function() {
      let dataList = [
        { title: '资产名称', key: 'name' },
        { title: '卡片号', key: 'label' },
        { title: this.$VUEAPPDEVICECODE(), key: 'code' },
        { title: '规格', key: 'code' },
        { title: '品牌', key: 'brandName' },
        { title: '型号', key: 'modelName' },

        { title: '本币原值(元)', key: 'price' },
        { title: '累计折旧(元)', key: '' },
        { title: '本年折旧(元)', key: '' },
        { title: '净值(元)', key: '' },
        { title: '减值准备(元)', key: '' },
        { title: '净额(元)', key: '' },
        { title: '资产类别', key: 'categoryName' },
        { title: '管理部门', key: 'tenantName' },
        { title: '使用部门', key: '' },
        { title: '使用人', key: '' },
        { title: '存放地点', key: 'address' },
        { title: '开始使用日期', key: 'createTime' },
        { title: '资产SN', key: 'sn' }
      ]

      this.dataList = dataList
      this.getDetail()
    },
    shouldDelete: function(index) {
      if (this.swiper != null) {
        this.swiper.slideTo(index)
      }
      this.index = index
      this.deleteDialogVisible = true
    },
    updateDeviceInfo: function() {
      let getSensors = (data) => {
        if (this.$valueIsExist(data, 'sensors') == false) {
          return ''
        }
        if (data['sensors'] == '') {
          return ''
        }
        let sensors = data['sensors']
        let sensorsId = ''
        for (let i = 0; i < sensors.length; i++) {
          let s = sensors[i]
          sensorsId = sensorsId + sensorsId == '' ? '' : ',' + s['id']
        }
        return sensorsId
      }

      let getAlgorithm = (data, post) => {
        if (this.$valueIsExist(data, 'algorithm') == false) {
          return
        }
        if (data['algorithm'] == '') {
          return
        }
        let algorithm = data['algorithm']
        for (const key in algorithm) {
          post[key] = algorithm[key]
        }
      }

      let getPhotos = (data, post) => {
        if (this.$valueIsExist(data, 'photos') == false) {
          post['photo'] = ''
          return
        }
        if (data['photos'] == '') {
          post['photo'] = ''
          return
        }
        let photos = data['photos']
        let photo = {}
        for (let i = 0; i < photos.length; i++) {
          let p = photos[i]
          let name = ''
          if (this.$valueIsExist(p, 'remark')) {
            name = p.remark
          }
          photo[p.id] = name
        }

        post['photo'] = JSON.stringify(photo)
      }

      let getDataInfo = () => {
        let dataInfo = {}
        for (const key in this.deviceInfo) {
          if (key == 'sensors') {
            dataInfo[key] = getSensors(this.deviceInfo)
            continue
          }
          if (key == 'algorithm') {
            dataInfo[key] = getAlgorithm(this.deviceInfo, dataInfo)
            continue
          }
          if (key == 'photos') {
            dataInfo[key] = getPhotos(this.deviceInfo, dataInfo)
            continue
          }
          dataInfo[key] = this.deviceInfo[key]
        }
        return dataInfo
      }

      let updateDeviceInfo = () => {
        let post = getDataInfo()
        console.log(post)
        this.$api.deviceManager.updateDevice(post).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.$message.success('更新成功')
          }
          this.getDetail()
          this.image = ''
          this.addImageDialogVisible = false
          this.saveImage('')
          this.index = 0
        }).catch(e => {
          this.loading = false
          this.image = ''
          this.addImageDialogVisible = false
          this.saveImage('')
          this.index = 0
        })
      }

      updateDeviceInfo()
    },

    saveImageToServer: function() {
      if (this.image == '') {
        return
      }

      let updateImageData = (res) => {
        if (this.$valueIsExist(res, 'id') == false) {
          this.$message.error('图片上传失败')
          return
        }
        let info = {
          id: res.id,
          remark: this.imageText,
          url: res.url
        }
        let list = []
        if (this.$valueIsExist(this.deviceInfo, 'photos') && this.deviceInfo['photos'] != '') {
          list = this.deviceInfo['photos']
        }
        list.push(info)
        this.deviceInfo['photos'] = list
        console.log(this.deviceInfo['photos'])
      }

      this.loading = true
      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.uploadImageToWeChatAndGet(this.image, corpInfo).then(res => {
            if (res.result == false) {
              this.$message.error('获取图片失败')
              this.loading = false
              return
            }
            updateImageData(res.image)
            this.updateDeviceInfo()

          })
        } catch (e) {
          this.$message.error('解析图片失败')
          this.loading = false
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>

@import "@styles/variables.scss";

.add_img {
  width: 260px;
  height: 260px;
  object-fit: scale-down;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0px);
}

.input-suffix {
  width: 100%;
  margin-top: 10px;

  .title {
    width: 70px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 13px;
    float: left;
  }

  .imageInput {
    width: calc(100% - 110px);
    margin-left: 10px;
    height: 28px;
    float: left;
  }

}

.deviceDetailManager {
  width: 100%;
  height: 100%;
  background-color: $background_color1;
  overflow-x: hidden;
  overflow-y: scroll;

  .more {
    width: 55px;
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    color: $color_primary;
  }

  .less {
    width: 55px;
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    color: $color3;
  }

  .imageContent {
    width: 100%;
    height: 500px;
    margin-top: 10px;
    margin-bottom: 10px;

    .deviceDetailView {
      width: 100%;
      height: 300px;
      margin-bottom: 50px;
      line-height: 300px;
      text-align: center;
      font-size: 30px;
      color: $color3;

      .deviceImg {
        display: block;
        width: 100%;
        height: 100%;
        //transform: translate(5.555%, 5.555%);
        object-fit: scale-down;
      }

    }

    .select_swiper-slide {
      background: #B4CDE6;
      opacity: 1;
    }

    .unselect_swiper-slide {
      background: #d4dfea;
      opacity: 0.5;
    }


    .swiper-slide {
      border-radius: 10px;
      overflow: hidden;
      text-align: center;
      font-size: 18px;

      width: 100%;
      height: 100%;


      .swiper-slide_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }

      .swiper-slide_text {
        line-height: 25px;
        width: 100%;
        height: 25px;
        transform: translate(0px, -100%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 15px
      }
    }

  }
}


::v-deep .arrow .swiper-button-prev:after {
  content: '';
}

::v-deep .arrow .swiper-button-next:after {
  content: '';
}

::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}

::v-deep .el-dialog .el-dialog__body {
  padding: 5px 20px 5px 20px;
  flex: 1;
  overflow: auto;
}


::v-deep .el-collapse-item .el-collapse-item__header {
  padding-left: 10px;
  border-bottom: none;
  justify-content: center;
}


::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
}


::v-deep .el-collapse {
  border-top: none;
  border-bottom: none;
}

::v-deep .el-collapse-item__arrow {
  margin: 0px;
}

//::v-deep .el-carousel__item--card.is-in-stage {
//  width: 100px;
//}


</style>
